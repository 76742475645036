import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import PageHeader from '../components/PageHeader'
import IG22ChartWrapper from '../components/IG22/IG22ChartWrapper'
import IG22Table from '../components/IG22/IG22Table'
import Footer from '../components/Footer'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_LOCATION_OPTIONS,
  //INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const INC_LOCATION_OPTIONS_ALT = [
  { id: 'WBLIC', label: 'Low Income' },
  { id: 'WBLMIC', label: 'Lower-Middle Income' },
  { id: 'WBUMIC', label: 'Upper-Middle Income' },
  { id: 'WBHIC', label: 'High Income', disabled: true }
]

const Ig22Page = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')
  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])
  //in case we need to know all vaccines in dataset
  //const xx = Array.from(new Set(data.regions.nodes.map((d) => d.DIMENSION2)))

  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS_ALT}
          uniOptions={UNI_LOCATION_OPTIONS}
          sectionId={sectionId}
        />

        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>
        {/* using a wrapper so data wrangling can happen in wrapper and be passed down to both Chart and Callout */}
        <IG22ChartWrapper
          regions={data.regions.nodes.filter(
            (d) => d.CODE === REGION_LOOKUP[reg]
          )}
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
          content={content}
          sectionId={sectionId}
          regional={reg !== 'GBL'} // if regional, callout will not show target indicator
          title={`${content.chartTitle}—${regionLabel}`}
        />

        <IG22Table
          regions={data.regions.nodes.filter(
            (d) => d.CODE === REGION_LOOKUP[reg]
          )}
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
        />
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Ig22Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleIg22CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      calloutRt
      calloutRtPt2
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }

    regions: allRegions(filter: { IA2030_IND: { eq: "IG2.2" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
        DIMENSION2
      }
    }
  }
`
//old
// global: googleIg22Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   vaccineIntro2020
//   vaccineIntro2021
//   vaccineIntro2022
//   vaccineIntro2023
//   vaccineIntro2024
//   vaccineIntro2025
//   vaccineIntro2026
//   vaccineIntro2027
//   vaccineIntro2028
//   vaccineIntro2029
//   vaccineIntro2030
//   cOVID2020
//   cOVID2021
//   cOVID2022
//   cOVID2023
//   cOVID2024
//   cOVID2025
//   cOVID2026
//   cOVID2027
//   cOVID2028
//   cOVID2029
//   cOVID2030
//   dTPBooster2020
//   dTPBooster2021
//   dTPBooster2022
//   dTPBooster2023
//   dTPBooster2024
//   dTPBooster2025
//   dTPBooster2026
//   dTPBooster2027
//   dTPBooster2028
//   dTPBooster2029
//   dTPBooster2030
//   hepBBD2020
//   hepBBD2021
//   hepBBD2022
//   hepBBD2023
//   hepBBD2024
//   hepBBD2025
//   hepBBD2026
//   hepBBD2027
//   hepBBD2028
//   hepBBD2029
//   hepBBD2030
//   hiB2020
//   hiB2021
//   hiB2022
//   hiB2023
//   hiB2024
//   hiB2025
//   hiB2026
//   hiB2027
//   hiB2028
//   hiB2029
//   hiB2030
//   hPV2020
//   hPV2021
//   hPV2022
//   hPV2023
//   hPV2024
//   hPV2025
//   hPV2026
//   hPV2027
//   hPV2028
//   hPV2029
//   hPV2030
//   iPV22020
//   iPV22021
//   iPV22022
//   iPV22023
//   iPV22024
//   iPV22025
//   iPV22026
//   iPV22027
//   iPV22028
//   iPV22029
//   iPV22030
//   pCV2020
//   pCV2021
//   pCV2022
//   pCV2023
//   pCV2024
//   pCV2025
//   pCV2026
//   pCV2027
//   pCV2028
//   pCV2029
//   pCV2030
//   rotavirus2020
//   rotavirus2021
//   rotavirus2022
//   rotavirus2023
//   rotavirus2024
//   rotavirus2025
//   rotavirus2026
//   rotavirus2027
//   rotavirus2028
//   rotavirus2029
//   rotavirus2030
//   rubella2020
//   rubella2021
//   rubella2022
//   rubella2023
//   rubella2024
//   rubella2025
//   rubella2026
//   rubella2027
//   rubella2028
//   rubella2029
//   rubella2030
//   cholera2020
//   cholera2021
//   cholera2022
//   cholera2023
//   cholera2024
//   cholera2025
//   cholera2026
//   cholera2027
//   cholera2028
//   cholera2029
//   cholera2030
//   hepA2020
//   hepA2021
//   hepA2022
//   hepA2023
//   hepA2024
//   hepA2025
//   hepA2026
//   hepA2027
//   hepA2028
//   hepA2029
//   hepA2030
//   influenza2020
//   influenza2021
//   influenza2022
//   influenza2023
//   influenza2024
//   influenza2025
//   influenza2026
//   influenza2027
//   influenza2028
//   influenza2029
//   influenza2030
//   jE2020
//   jE2021
//   jE2022
//   jE2023
//   jE2024
//   jE2025
//   jE2026
//   jE2027
//   jE2028
//   jE2029
//   jE2030
//   menA2020
//   menA2021
//   menA2022
//   menA2023
//   menA2024
//   menA2025
//   menA2026
//   menA2027
//   menA2028
//   menA2029
//   menA2030
//   multivariantMen2020
//   multivariantMen2021
//   multivariantMen2022
//   multivariantMen2023
//   multivariantMen2024
//   multivariantMen2025
//   multivariantMen2026
//   multivariantMen2027
//   multivariantMen2028
//   multivariantMen2029
//   multivariantMen2030
//   mumps2020
//   mumps2021
//   mumps2022
//   mumps2023
//   mumps2024
//   mumps2025
//   mumps2026
//   mumps2027
//   mumps2028
//   mumps2029
//   mumps2030
//   rabies2020
//   rabies2021
//   rabies2022
//   rabies2023
//   rabies2024
//   rabies2025
//   rabies2026
//   rabies2027
//   rabies2028
//   rabies2029
//   rabies2030
//   typhoid2020
//   typhoid2021
//   typhoid2022
//   typhoid2023
//   typhoid2024
//   typhoid2025
//   typhoid2026
//   typhoid2027
//   typhoid2028
//   typhoid2029
//   typhoid2030
//   varicella2020
//   varicella2021
//   varicella2022
//   varicella2023
//   varicella2024
//   varicella2025
//   varicella2026
//   varicella2027
//   varicella2028
//   varicella2029
//   varicella2030
//   yF2020
//   yF2021
//   yF2022
//   yF2020
//   yF2023
//   yF2024
//   yF2025
//   yF2026
//   yF2027
//   yF2028
//   yF2029
//   mCV22020
//   mCV22021
//   mCV22022
//   mCV22023
//   mCV22024
//   mCV22025
//   mCV22026
//   mCV22027
//   mCV22028
//   mCV22029
//   mCV22030
// }
// regions: allGoogleIg22Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     vaccineIntro2020
//     vaccineIntro2021
//     vaccineIntro2022
//     vaccineIntro2023
//     vaccineIntro2024
//     vaccineIntro2025
//     vaccineIntro2026
//     vaccineIntro2027
//     vaccineIntro2028
//     vaccineIntro2029
//     vaccineIntro2030
//     cOVID2020
//     cOVID2021
//     cOVID2022
//     cOVID2023
//     cOVID2024
//     cOVID2025
//     cOVID2026
//     cOVID2027
//     cOVID2028
//     cOVID2029
//     cOVID2030
//     dTPBooster2020
//     dTPBooster2021
//     dTPBooster2022
//     dTPBooster2023
//     dTPBooster2024
//     dTPBooster2025
//     dTPBooster2026
//     dTPBooster2027
//     dTPBooster2028
//     dTPBooster2029
//     dTPBooster2030
//     hepBBD2020
//     hepBBD2021
//     hepBBD2022
//     hepBBD2023
//     hepBBD2024
//     hepBBD2025
//     hepBBD2026
//     hepBBD2027
//     hepBBD2028
//     hepBBD2029
//     hepBBD2030
//     hiB2020
//     hiB2021
//     hiB2022
//     hiB2023
//     hiB2024
//     hiB2025
//     hiB2026
//     hiB2027
//     hiB2028
//     hiB2029
//     hiB2030
//     hPV2020
//     hPV2021
//     hPV2022
//     hPV2023
//     hPV2024
//     hPV2025
//     hPV2026
//     hPV2027
//     hPV2028
//     hPV2029
//     hPV2030
//     iPV22020
//     iPV22021
//     iPV22022
//     iPV22023
//     iPV22024
//     iPV22025
//     iPV22026
//     iPV22027
//     iPV22028
//     iPV22029
//     iPV22030
//     pCV2020
//     pCV2021
//     pCV2022
//     pCV2023
//     pCV2024
//     pCV2025
//     pCV2026
//     pCV2027
//     pCV2028
//     pCV2029
//     pCV2030
//     rotavirus2020
//     rotavirus2021
//     rotavirus2022
//     rotavirus2023
//     rotavirus2024
//     rotavirus2025
//     rotavirus2026
//     rotavirus2027
//     rotavirus2028
//     rotavirus2029
//     rotavirus2030
//     rubella2020
//     rubella2021
//     rubella2022
//     rubella2023
//     rubella2024
//     rubella2025
//     rubella2026
//     rubella2027
//     rubella2028
//     rubella2029
//     rubella2030
//     cholera2020
//     cholera2021
//     cholera2022
//     cholera2023
//     cholera2024
//     cholera2025
//     cholera2026
//     cholera2027
//     cholera2028
//     cholera2029
//     cholera2030
//     hepA2020
//     hepA2021
//     hepA2022
//     hepA2023
//     hepA2024
//     hepA2025
//     hepA2026
//     hepA2027
//     hepA2028
//     hepA2029
//     hepA2030
//     influenza2020
//     influenza2021
//     influenza2022
//     influenza2023
//     influenza2024
//     influenza2025
//     influenza2026
//     influenza2027
//     influenza2028
//     influenza2029
//     influenza2030
//     jE2020
//     jE2021
//     jE2022
//     jE2023
//     jE2024
//     jE2025
//     jE2026
//     jE2027
//     jE2028
//     jE2029
//     jE2030
//     menA2020
//     menA2021
//     menA2022
//     menA2023
//     menA2024
//     menA2025
//     menA2026
//     menA2027
//     menA2028
//     menA2029
//     menA2030
//     multivariantMen2020
//     multivariantMen2021
//     multivariantMen2022
//     multivariantMen2023
//     multivariantMen2024
//     multivariantMen2025
//     multivariantMen2026
//     multivariantMen2027
//     multivariantMen2028
//     multivariantMen2029
//     multivariantMen2030
//     mumps2020
//     mumps2021
//     mumps2022
//     mumps2023
//     mumps2024
//     mumps2025
//     mumps2026
//     mumps2027
//     mumps2028
//     mumps2029
//     mumps2030
//     rabies2020
//     rabies2021
//     rabies2022
//     rabies2023
//     rabies2024
//     rabies2025
//     rabies2026
//     rabies2027
//     rabies2028
//     rabies2029
//     rabies2030
//     typhoid2020
//     typhoid2021
//     typhoid2022
//     typhoid2023
//     typhoid2024
//     typhoid2025
//     typhoid2026
//     typhoid2027
//     typhoid2028
//     typhoid2029
//     typhoid2030
//     varicella2020
//     varicella2021
//     varicella2022
//     varicella2023
//     varicella2024
//     varicella2025
//     varicella2026
//     varicella2027
//     varicella2028
//     varicella2029
//     varicella2030
//     yF2020
//     yF2021
//     yF2022
//     yF2020
//     yF2023
//     yF2024
//     yF2025
//     yF2026
//     yF2027
//     yF2028
//     yF2029
//     mCV22020
//     mCV22021
//     mCV22022
//     mCV22023
//     mCV22024
//     mCV22025
//     mCV22026
//     mCV22027
//     mCV22028
//     mCV22029
//     mCV22030
//   }
// }
// income: allGoogleIg22Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     incomeGroup
//     vaccineIntro2020
//     vaccineIntro2021
//     vaccineIntro2022
//     vaccineIntro2023
//     vaccineIntro2024
//     vaccineIntro2025
//     vaccineIntro2026
//     vaccineIntro2027
//     vaccineIntro2028
//     vaccineIntro2029
//     vaccineIntro2030
//     cOVID2020
//     cOVID2021
//     cOVID2022
//     cOVID2023
//     cOVID2024
//     cOVID2025
//     cOVID2026
//     cOVID2027
//     cOVID2028
//     cOVID2029
//     cOVID2030
//     dTPBooster2020
//     dTPBooster2021
//     dTPBooster2022
//     dTPBooster2023
//     dTPBooster2024
//     dTPBooster2025
//     dTPBooster2026
//     dTPBooster2027
//     dTPBooster2028
//     dTPBooster2029
//     dTPBooster2030
//     hepBBD2020
//     hepBBD2021
//     hepBBD2022
//     hepBBD2023
//     hepBBD2024
//     hepBBD2025
//     hepBBD2026
//     hepBBD2027
//     hepBBD2028
//     hepBBD2029
//     hepBBD2030
//     hiB2020
//     hiB2021
//     hiB2022
//     hiB2023
//     hiB2024
//     hiB2025
//     hiB2026
//     hiB2027
//     hiB2028
//     hiB2029
//     hiB2030
//     hPV2020
//     hPV2021
//     hPV2022
//     hPV2023
//     hPV2024
//     hPV2025
//     hPV2026
//     hPV2027
//     hPV2028
//     hPV2029
//     hPV2030
//     iPV22020
//     iPV22021
//     iPV22022
//     iPV22023
//     iPV22024
//     iPV22025
//     iPV22026
//     iPV22027
//     iPV22028
//     iPV22029
//     iPV22030
//     pCV2020
//     pCV2021
//     pCV2022
//     pCV2023
//     pCV2024
//     pCV2025
//     pCV2026
//     pCV2027
//     pCV2028
//     pCV2029
//     pCV2030
//     rotavirus2020
//     rotavirus2021
//     rotavirus2022
//     rotavirus2023
//     rotavirus2024
//     rotavirus2025
//     rotavirus2026
//     rotavirus2027
//     rotavirus2028
//     rotavirus2029
//     rotavirus2030
//     rubella2020
//     rubella2021
//     rubella2022
//     rubella2023
//     rubella2024
//     rubella2025
//     rubella2026
//     rubella2027
//     rubella2028
//     rubella2029
//     rubella2030
//     cholera2020
//     cholera2021
//     cholera2022
//     cholera2023
//     cholera2024
//     cholera2025
//     cholera2026
//     cholera2027
//     cholera2028
//     cholera2029
//     cholera2030
//     hepA2020
//     hepA2021
//     hepA2022
//     hepA2023
//     hepA2024
//     hepA2025
//     hepA2026
//     hepA2027
//     hepA2028
//     hepA2029
//     hepA2030
//     influenza2020
//     influenza2021
//     influenza2022
//     influenza2023
//     influenza2024
//     influenza2025
//     influenza2026
//     influenza2027
//     influenza2028
//     influenza2029
//     influenza2030
//     jE2020
//     jE2021
//     jE2022
//     jE2023
//     jE2024
//     jE2025
//     jE2026
//     jE2027
//     jE2028
//     jE2029
//     jE2030
//     menA2020
//     menA2021
//     menA2022
//     menA2023
//     menA2024
//     menA2025
//     menA2026
//     menA2027
//     menA2028
//     menA2029
//     menA2030
//     multivariantMen2020
//     multivariantMen2021
//     multivariantMen2022
//     multivariantMen2023
//     multivariantMen2024
//     multivariantMen2025
//     multivariantMen2026
//     multivariantMen2027
//     multivariantMen2028
//     multivariantMen2029
//     multivariantMen2030
//     mumps2020
//     mumps2021
//     mumps2022
//     mumps2023
//     mumps2024
//     mumps2025
//     mumps2026
//     mumps2027
//     mumps2028
//     mumps2029
//     mumps2030
//     rabies2020
//     rabies2021
//     rabies2022
//     rabies2023
//     rabies2024
//     rabies2025
//     rabies2026
//     rabies2027
//     rabies2028
//     rabies2029
//     rabies2030
//     typhoid2020
//     typhoid2021
//     typhoid2022
//     typhoid2023
//     typhoid2024
//     typhoid2025
//     typhoid2026
//     typhoid2027
//     typhoid2028
//     typhoid2029
//     typhoid2030
//     varicella2020
//     varicella2021
//     varicella2022
//     varicella2023
//     varicella2024
//     varicella2025
//     varicella2026
//     varicella2027
//     varicella2028
//     varicella2029
//     varicella2030
//     yF2020
//     yF2021
//     yF2022
//     yF2020
//     yF2023
//     yF2024
//     yF2025
//     yF2026
//     yF2027
//     yF2028
//     yF2029
//     mCV22020
//     mCV22021
//     mCV22022
//     mCV22023
//     mCV22024
//     mCV22025
//     mCV22026
//     mCV22027
//     mCV22028
//     mCV22029
//     mCV22030
//   }
// }
// unicef: allGoogleIg22Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     uNICEFRegion
//     vaccineIntro2020
//     vaccineIntro2021
//     vaccineIntro2022
//     vaccineIntro2023
//     vaccineIntro2024
//     vaccineIntro2025
//     vaccineIntro2026
//     vaccineIntro2027
//     vaccineIntro2028
//     vaccineIntro2029
//     vaccineIntro2030
//     cOVID2020
//     cOVID2021
//     cOVID2022
//     cOVID2023
//     cOVID2024
//     cOVID2025
//     cOVID2026
//     cOVID2027
//     cOVID2028
//     cOVID2029
//     cOVID2030
//     dTPBooster2020
//     dTPBooster2021
//     dTPBooster2022
//     dTPBooster2023
//     dTPBooster2024
//     dTPBooster2025
//     dTPBooster2026
//     dTPBooster2027
//     dTPBooster2028
//     dTPBooster2029
//     dTPBooster2030
//     hepBBD2020
//     hepBBD2021
//     hepBBD2022
//     hepBBD2023
//     hepBBD2024
//     hepBBD2025
//     hepBBD2026
//     hepBBD2027
//     hepBBD2028
//     hepBBD2029
//     hepBBD2030
//     hiB2020
//     hiB2021
//     hiB2022
//     hiB2023
//     hiB2024
//     hiB2025
//     hiB2026
//     hiB2027
//     hiB2028
//     hiB2029
//     hiB2030
//     hPV2020
//     hPV2021
//     hPV2022
//     hPV2023
//     hPV2024
//     hPV2025
//     hPV2026
//     hPV2027
//     hPV2028
//     hPV2029
//     hPV2030
//     iPV22020
//     iPV22021
//     iPV22022
//     iPV22023
//     iPV22024
//     iPV22025
//     iPV22026
//     iPV22027
//     iPV22028
//     iPV22029
//     iPV22030
//     pCV2020
//     pCV2021
//     pCV2022
//     pCV2023
//     pCV2024
//     pCV2025
//     pCV2026
//     pCV2027
//     pCV2028
//     pCV2029
//     pCV2030
//     rotavirus2020
//     rotavirus2021
//     rotavirus2022
//     rotavirus2023
//     rotavirus2024
//     rotavirus2025
//     rotavirus2026
//     rotavirus2027
//     rotavirus2028
//     rotavirus2029
//     rotavirus2030
//     rubella2020
//     rubella2021
//     rubella2022
//     rubella2023
//     rubella2024
//     rubella2025
//     rubella2026
//     rubella2027
//     rubella2028
//     rubella2029
//     rubella2030
//     cholera2020
//     cholera2021
//     cholera2022
//     cholera2023
//     cholera2024
//     cholera2025
//     cholera2026
//     cholera2027
//     cholera2028
//     cholera2029
//     cholera2030
//     hepA2020
//     hepA2021
//     hepA2022
//     hepA2023
//     hepA2024
//     hepA2025
//     hepA2026
//     hepA2027
//     hepA2028
//     hepA2029
//     hepA2030
//     influenza2020
//     influenza2021
//     influenza2022
//     influenza2023
//     influenza2024
//     influenza2025
//     influenza2026
//     influenza2027
//     influenza2028
//     influenza2029
//     influenza2030
//     jE2020
//     jE2021
//     jE2022
//     jE2023
//     jE2024
//     jE2025
//     jE2026
//     jE2027
//     jE2028
//     jE2029
//     jE2030
//     menA2020
//     menA2021
//     menA2022
//     menA2023
//     menA2024
//     menA2025
//     menA2026
//     menA2027
//     menA2028
//     menA2029
//     menA2030
//     multivariantMen2020
//     multivariantMen2021
//     multivariantMen2022
//     multivariantMen2023
//     multivariantMen2024
//     multivariantMen2025
//     multivariantMen2026
//     multivariantMen2027
//     multivariantMen2028
//     multivariantMen2029
//     multivariantMen2030
//     mumps2020
//     mumps2021
//     mumps2022
//     mumps2023
//     mumps2024
//     mumps2025
//     mumps2026
//     mumps2027
//     mumps2028
//     mumps2029
//     mumps2030
//     rabies2020
//     rabies2021
//     rabies2022
//     rabies2023
//     rabies2024
//     rabies2025
//     rabies2026
//     rabies2027
//     rabies2028
//     rabies2029
//     rabies2030
//     typhoid2020
//     typhoid2021
//     typhoid2022
//     typhoid2023
//     typhoid2024
//     typhoid2025
//     typhoid2026
//     typhoid2027
//     typhoid2028
//     typhoid2029
//     typhoid2030
//     varicella2020
//     varicella2021
//     varicella2022
//     varicella2023
//     varicella2024
//     varicella2025
//     varicella2026
//     varicella2027
//     varicella2028
//     varicella2029
//     varicella2030
//     yF2020
//     yF2021
//     yF2022
//     yF2020
//     yF2023
//     yF2024
//     yF2025
//     yF2026
//     yF2027
//     yF2028
//     yF2029
//     mCV22020
//     mCV22021
//     mCV22022
//     mCV22023
//     mCV22024
//     mCV22025
//     mCV22026
//     mCV22027
//     mCV22028
//     mCV22029
//     mCV22030
//   }
// }
